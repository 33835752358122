@tailwind base;
@tailwind components;
@tailwind utilities;

.effect-box:after,
.effect-box:before {
    content: "";
    position: absolute;
    left: -7;
    display: inline-block;
    height: 5em;
    width: 6em;
    margin-top: 5px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.effect-box:before {
    border-left: 3px solid white;
    border-right: 3px solid white;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
}

.effect-box:after {
    border-bottom: 3px solid white;
    border-top: 3px solid white;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
}

.effect-box:hover:after,
.effect-box:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.effect-box2:after,
.effect-box2:before {
    content: "";
    position: absolute;
    left: -7;
    display: inline-block;
    height: 9em;
    width: 9em;
    margin-top: 5px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

.effect-box2:before {
    border-left: 3px solid white;
    border-right: 3px solid white;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
}

.effect-box2:after {
    border-bottom: 3px solid white;
    border-top: 3px solid white;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
}

.effect-box2:hover:after,
.effect-box2:hover:before {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

*::-webkit-scrollbar {
    display: none;
}

.typewriter {
    overflow: hidden;
    border-right: 0.1em solid transparent;
    white-space: nowrap;
    width: 0;
    animation: typing 1.2s steps(16, end) forwards, border 1.2s linear forwards;
}

.typewriter2 {
    overflow: hidden;
    border-right: 0.1em solid transparent;
    white-space: nowrap;
    width: 0;
    animation: typing 2.2s steps(32, end) forwards, border 3s linear forwards;
    animation-delay: 1.5s;
}

@keyframes typing {
    from {
        width: 0;
    }
    5% {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes blink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: white;
    }
}
@keyframes border {
    from,
    to {
        border-color: transparent;
    }
    1% {
        border-color: white;
    }
    99% {
        border-color: white;
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

h1 {
    color: #f36c5d;
    font-size: 5rem;
    margin-bottom: 1rem;
    font-weight: bold;
}

h2 {
    color: #ffffff;
    font-size: 3.125rem;
    margin-bottom: 2.5rem;
    font-weight: 600;
}
h3 {
    color: #ffffff;
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    margin-bottom: 2rem;
    font-weight: 600;
}

p {
    color: #ffffff;
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
}

.ch:checked ~ .barIcon {
    display: none;
}

.ch:checked ~ .closeIcon {
    display: block;
}

.ch:checked ~ .slider {
    top: -2px;
}

.back {
    background: rgb(0, 0, 0);
    background: radial-gradient(
        circle,
        rgba(22, 29, 50, 1) 0%,
        rgba(0, 0, 0, 1) 80%
    );
}

.shad:hover {
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.528);
}
